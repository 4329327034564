<template>
  <v-container fluid class="ma-0 pa-0">
    <Navigation></Navigation>
    <indexCarousel></indexCarousel>    
    <v-container  fluid style="max-width: 1750px;">
      <v-container class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-text style="font-size: 32px; color: #5b5b5b; text-shadow: #d4d4d4 3px 3px 5px; font-family: Arial Black, sans-serif; font-style: italic;">Ihre Ansprechpartner/innen</v-text>
          <v-row>
            <v-col class="mt-8" cols="6" v-for="(item, index) in items" :key="index">
                <v-avatar size="210" class="mx-auto">
                  <img :src="item.image" alt="Employee Image">
                </v-avatar>
                <h3 class="mt-4">{{ item.name }}</h3>
                <v-text class="text-caption" v-if="item.position">{{ item.position }}</v-text><br/>
                <v-text class="text-caption" v-if="item.telephone">Telefon: {{ item.telephone }}</v-text>
                <v-text class="text-caption" v-if="item.mobile">Mobil: {{ item.mobile }}</v-text>
                <v-text class="text-caption" v-if="item.email">E-Mail: {{ item.email }}</v-text>
            </v-col>
          </v-row>
        </v-container>
      
      
      <v-container v-if="$vuetify.breakpoint.smAndDown">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-text style="font-size: 32px; color: #5b5b5b; text-shadow: #d4d4d4 3px 3px 5px; font-family: Arial Black, sans-serif; font-style: italic;">Ihre Ansprechpartner/innen</v-text>
            <v-col cols="12 mt-4" v-for="(item, index) in items" :key="index">
              <v-text class="text-center">
                <v-avatar size="210" class="mx-auto">
                  <img :src="item.image" alt="Employee Image">
                </v-avatar>
                <h3 class="mb-1">{{ item.name }}</h3>
                <v-text class="text-caption" v-if="item.position">{{ item.position }}</v-text><br/>
                <v-text class="text-caption" v-if="item.telephone">Telefon: {{ item.telephone }}</v-text>
                <v-text class="text-caption" v-if="item.mobile">Mobil: {{ item.mobile }}</v-text>
                <v-text class="text-caption" v-if="item.email">E-Mail: {{ item.email }}</v-text>
              </v-text>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
        

    <v-container style="max-width: 960px;">
      <h4>Füllen Sie folgendes Formular aus, um ein kostenloses Beratungsgespräch zu vereinbaren.</h4>
      <small>Einträge die mit einem * makiert sind, müssen ausgefüllt werden!</small> <br/><br/>
        <v-form @submit.prevent="sendContactRequest">
          <v-text-field clearable v-model="firstName" label="Vorname *" required></v-text-field>
          <v-text-field clearable v-model="lastName" label="Nachname *" required></v-text-field>
          <v-text-field clearable v-model="phone" label="Telefon *" type="phone" required></v-text-field>
          <v-text-field clearable v-model="email" label="E-Mail *" type="email" required></v-text-field>
          <v-text-field clearable v-model="postal" label="PLZ / Ort *" type="postal" required></v-text-field>
          <v-textarea :auto-grow="true" v-model="message" label="Was möchten Sie uns mitteilen?"></v-textarea>
          <br/>
          <v-text>Ich nehme zur Kenntnis: *</v-text>
          <v-checkbox v-model="checkbox1" label="Durch das Absenden dieser Nachricht kommt noch kein verbindlicher Vermittlungsvertrag zustande. Nach Absendung dieses Formulars werde ich zeitnah durch die AP Management LORENZ®, i.d.R. telefonisch kontaktiert. Hierbei werden die Rahmenbedingungen und weiteres besprochen. Nach Einigung wird ein entsprechender Vermittlungsvertrag geschlossen." required></v-checkbox>
          <v-text>Ich nehme zur Kenntnis: *</v-text>
          <v-checkbox v-model="checkbox2" label="Hiermit erkläre ich mein Einverständnis, dass die AP Management LORENZ® meine vorliegenden Daten für eine ordnungsgemäße Auftragsausführung speichert. Meine Daten werden nicht an Dritte weitergegeben. Zudem erkläre ich mich einverstanden, von der AP Management LORENZ® telefonisch kontaktiert zu werden um die Auftragsvergabe abzuschließen. Diese Einverständniserklärung kann ich jederzeit widerrufen und es besteht kein Rechtsanspruch auf Nutzung meiner Daten durch die AP Management LORENZ®" required></v-checkbox>
          <v-btn type="submit" color="error">Senden</v-btn>
        </v-form>
      </v-container>
    </v-container>
    <MainFooter class="ma-0 pa-0"></MainFooter>
  </v-container>
  </template>
  
  <script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";
  import axios from "axios";
  
  export default {
      name: 'MainIndex',
  
      components: {
        Navigation,
        indexCarousel,
        MainFooter
      },

      data() {
        return {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          postal: '',
          message: '',
          checkbox1: false,
          checkbox2: false,
          items: [
            // { name: 'Hanna Weber', position: 'Geschäftsleiterin / Kundenbetreuung', telephone: '03731 7759900', mobile: '01514 2123323', email: 'hanna.weber@ap-management.info', image: 'https://ap-management.info/____impro/1/onewebmedia/2-modified.png?etag=%221fc63e-63c7a857%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=258%2B258' },
            { name: 'David Lorenz', position: 'Geschäftsführer', telephone: '', mobile: '', email: 'kontakt@ap-management.info', image: 'https://ap-management.info/____impro/1/onewebmedia/Hochzeit_128_Fotoforma_IMG_8773-modified.png?etag=%222a85ca-63c7b685%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=258%2B258' },
            { name: 'Anja Kreyßel', position: 'Auszubildende', telephone: '03731 7759910', mobile: '', email: 'anja.kreyssel@ap-management.info', image: 'https://ap-management.info/____impro/1/onewebmedia/2-modified%20%281%29.png?etag=%221cb11c-63c7bb7a%22&sourceContentType=image%2Fpng&ignoreAspectRatio&resize=258%2B258' },
          ]
        };
      },

      methods: {
        async sendContactRequest() {
          const response = await axios.post(`http://api.justinbladt.de:3000/contact-request`, {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            email: this.email,
            postal: this.postal,
            message: this.message,
            checkbox1: this.checkbox1,
            checkbox2: this.checkbox2
          });

          console.log(response.data);
        }
      }
    };
  
  </script>
  