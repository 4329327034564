<template>
  <v-container fluid class="ma-0 pa-0" style="background:#f0f0f0">
    <Navigation></Navigation>
    <indexCarousel></indexCarousel>    
    <v-container fluid style="max-width: 1750px; background:#f0f0f0">
      <v-row>
        <v-col cols="12" md="12" class="text-center ma-4">
          <h1 style="font-size: 32px; color: #5b5b5b; text-shadow: #d4d4d4 3px 3px 5px; font-family: Arial Black, sans-serif; font-style: italic;">Sie suchen passende Mitarbeiter ?</h1>
        </v-col>

        <v-col cols="12" md="7" class="text-center align-self-center" style="padding: 0; justify-content: end;">
            <v-text style="font-weight: 600; font-size: larger; width: 30%; position: relative;">
              Reduzieren Sie wertvolle Zeit und Kosten bei der Personalsuche.<br/><br/>
              Individuell nach Ihren Zielvorgaben und auf Ihr Anforderungsprofil ausgerichtet,<br/>
              suchen wir Mitarbeiter und schlagen Ihnen passende Bewerber vor.<br/><br/>
              Vertrauen Sie unseren Erfahrungen und reduzieren Sie Zeit und Kosten bei der Personalsuche.
            </v-text>
        </v-col>
        <v-col cols="12" md="5">
          <v-img class="mr-8" src="../assets/ag_pic1.jpg" :height="525" :width="1425"></v-img>
        </v-col>
      </v-row>
    </v-container>

	<v-container fluid class="pa-0 ma-3">
		<v-img src="../assets/bg_black.jpg" :max-height="690">
			<v-text class="ma-2 pa-2 text-center white--text" style="font-weight: 600; transform: rotate(-30deg); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
				<h1>Wir bieten Ihnen</h1>
			</v-text>
			
			<v-row v-if="$vuetify.breakpoint.mdAndUp">
				<v-col cols="12" md="4">
					<v-container style="position: absolute; left: 50%; right: 0%; z-index: 9;">
						<v-card flat tile color="#FDFEFE" height="300">
							<v-card-title primary-title class="justify-start align-start layout pb-0">
								•  Schnelle und effiziente Stellenbesetzung<br/>
								•  Nutzung aller Rekrutierungskanäle<br/>
								•  Erstellung und Veröffentlichung von Stellenanzeigen<br/>
								•  Sichtung eingehender Bewerbungen<br/>
								•  Professionelle Bewerbervorauswahl<br/>
								•  Persönliche Bewerbergespräche<br/>
								•  Pool an sofort verfügbaren Kandidaten<br/>
								•  Persönliche Betreuung
							</v-card-title>
						</v-card>
					</v-container>
				</v-col>
			</v-row>

			<v-row v-if="$vuetify.breakpoint.smAndDown">
				<v-container class="pa-5">
					<v-card flat tile height="300">
						<v-card-title primary-title class="justify-start align-start layout pb-0">
							•  Schnelle und effiziente Stellenbesetzung<br/>
							•  Nutzung aller Rekrutierungskanäle<br/>
							•  Erstellung und Veröffentlichung von Stellenanzeigen<br/>
							•  Sichtung eingehender Bewerbungen<br/>
							•  Professionelle Bewerbervorauswahl<br/>
							•  Persönliche Bewerbergespräche<br/>
							•  Pool an sofort verfügbaren Kandidaten<br/>
							•  Persönliche Betreuung
						</v-card-title>
					</v-card>
				</v-container>
			</v-row>
			
			<v-row v-if="$vuetify.breakpoint.mdAndUp">
				<v-col cols="12" md="6">
					<v-img src="../assets/happybiz.jpg" :max-height="960" :max-width="960" style="position: relative; left: 25%"></v-img>
				</v-col>
			</v-row>

			<v-row v-if="$vuetify.breakpoint.smAndDown">
				<v-col cols="12">
					<v-img src="../assets/happybiz.jpg" :max-height="512" :max-width="512" style="position: relative; left: 5%;"></v-img>
				</v-col>
			</v-row>
		</v-img>
	</v-container>
	
	<v-container fluid style="max-width: 1360px; background:#f0f0f0">
					<v-col cols="12">
						<v-container fluid class="text-center">
							<v-divider></v-divider>
							<h1 style="text-shadow: #d4d4d4 3px 3px 5px;">Welche Kosten entstehen bei erfolgreicher Arbeitsvermittlung ?</h1>
							<h2 style="font-weight: 500;text-shadow: #d4d4d4 3px 3px 5px;">Für Bewerber sind unsere Leistungen stets kostenlos !</h2>
							<v-divider></v-divider>
						</v-container>
					</v-col>
		<!-- <v-col cols="12" class="ma-4"> -->
			<v-row>
			<v-col cols="12" md="4">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/job_1.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(firstItem.path)">
								<h1>
									Stellenportal
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
			<v-col cols="12" md="4">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/job_2.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(secondItem.path)">
								<h1>
									Initiativbewerbung
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
			<v-col cols="12" md="4">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/ag_pic3.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(thirdItem.path)">
								<h1>
									Kontakt zu uns!
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
		</v-row>
        <!-- </v-col> -->
</v-container>

<MainFooter class="ma-0 pa-0"></MainFooter>
</v-container>
</template>
  
  <script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";
  
  export default {
      name: 'MainIndex',
  
      components: {
        Navigation,
        indexCarousel,
        MainFooter
      },
  
      data() {
            return {
              firstItem: { title: "Für Arbeitgeber", path: "/stellenangebote", },
              secondItem: { title: "Für Bewerber", path: "/initiativbewerbung", },
              thirdItem: { title: "Kontakt zu uns!", path: "/kontakt", },
            };
		},
		methods: {
			visitWebsite(path) {
				this.$router.push(path).catch(()=>{});
				this.scrollToTop();
			},
			
			scrollToTop() {
				window.scrollTo(0, 0);
			}
		},
		
		created() {
			this.scrollToTop();
		}
    }
  </script>
  