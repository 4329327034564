<template>
	<v-container fluid>
		<v-footer style="background-image:linear-gradient(180deg, rgba(63,63,63,0.39) 0%, rgba(255,255,255,0.39) 58%)">
			<v-container fluid class="text-center">
				<v-row>
					<v-col cols="12" md="4">
						<v-text style="font-weight: 700;">
							AP Management LORENZ UG <br/>
							(haftungsbeschränkt) <br/>
							Hornstraße 15<br/>
							09599 Freiberg
							<a href="https://unternehmensgruppe-lorenz.info">
								<v-img src="../assets/logo_gruppe.jpg" transition="scale-transition" max-width="319" max-height="62" class="mx-auto mt-2" />
							</a>
						</v-text>
					</v-col>
					<v-col cols="12" md="4">
						<v-text style="font-weight: 700;">
							<br/>
							Telefon: 03731 7759900<br/><br/>
							E-Mail: kontakt@ap-management.info
						</v-text> <br/><br/>

						<v-btn class="mr-3 my-2" text @click="visitWebsite(impressItem.path)" style="background-color: red;">
							<router-link :to="impressItem.path" style="color: white; text-decoration: none;"> 
								{{ impressItem.title }}
							</router-link>
						</v-btn>

						<v-btn text @click="visitWebsite(dataItem.path)" style="background-color: red;">
							<router-link :to="dataItem.path" style="color: white; text-decoration: none;"> 
								{{ dataItem.title }}
							</router-link>
						</v-btn>

						<v-footer  class="text-center">
							Copyright © 2018 - 2024 - AP Management LORENZ® - Gesellschaft für Personaldienstleistungen
						</v-footer>
					</v-col>
					<v-col cols="12" md="4">
						<v-text style="font-weight: 700;">
							<br/>
							Geschäftszeiten:<br/>
							Mo. - Fr.: 09.00 - 15.00 Uhr<br/>
							sowie nach Vereinbarung
						</v-text>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
	</v-container>
	</template>

<script>
export default {
	name: 'MainFooter',

	data() {
            return {
			impressItem: { title: "Impressum", path: "/impressum", },
            dataItem: { title: "Datenschutz", path: "/datenschutz", }
            };
		},

		methods: {
              selectItem(item) {
                this.selectedItem = item;
              },
              selectSecondItem(item) {
                this.selectedItem = {};
                Object.assign(this.selectedItem, { title: this.secondSelectedItem.title }, item);
              },
              visitWebsite(path) {
                this.$router.push(path).catch(()=>{});
              },
            },
	
	components: {
		
	}
  }
</script>
