import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/kontaktView.vue'
import Impressum from '../views/ImpressumView.vue'
import Datenschutz from '../views/DatenschutzView.vue'
import ArbeitgeberView from '../views/AgView.vue'
import BewerberView from '../views/BwView.vue'
import pbView from '../views/personalBedarfView.vue'
// import AlphaView from '../views/AlphaConnectView.vue'
// import Stellenangebote from '../views/StellenangeboteView.vue'
// import Initiativbewerbung from '../views/InitiativBewerbungView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/startseite',
    name: 'home',
    component: HomeView
  },
  {
    path: '/arbeitgeber',
    name: 'arbeitgeber',
    component: ArbeitgeberView
  },
  {
    path: '/personalbedarf-melden',
    name: 'personalbedarf-melden',
    component: pbView
  },
  {
    path: '/bewerber',
    name: 'bewerber',
    component: BewerberView
  },
  // {
  //   path: '/stellenangebote',
  //   name: 'stellenangebote',
  //   component: Stellenangebote
  // },
  // {
  //   path: '/initiativbewerbung',
  //   name: 'initiativbewerbung',
  //   component: Initiativbewerbung
  // },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: AboutView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'exact-active'
});

// make the home route as the default route
router.push('/startseite');



export default router;
