<template>
  <v-container fluid class="ma-0 pa-0">
    <Navigation></Navigation>
    <indexCarousel></indexCarousel>    
    <v-container  fluid style="max-width: 1750px;">
    <v-container class="text-center" style="max-width: 960px;">
      <v-text>
        <h2>Benötigen Sie Unterstützung bei der Suche nach qualifiziertem Personal?</h2><br/>
      </v-text>
    </v-container>
    <v-container style="max-width: 960px;">
      <h4>Füllen Sie folgendes Formular aus, um ein kostenloses Beratungsgespräch zu vereinbaren.</h4>
      <small>Einträge die mit einem * makiert sind, müssen ausgefüllt werden!</small> <br/><br/>
        <v-form @submit.prevent="sendPersonalContactRequest" >
          <v-text-field clearable v-model="companyName" label="Firmenname *" :rules="noEmptyRule" :error="companyNameError" required></v-text-field>
          <v-text-field clearable v-model="companyPlace" label="Anschrift *" :rules="noEmptyRule" required></v-text-field>
          <v-text-field clearable v-model="companyPostal" label="PLZ *" type="number" :rules="noEmptyNumbRule" required></v-text-field>
          <v-text-field clearable v-model="companyCity" label="Ort *" :rules="noEmptyRule" required></v-text-field>
          <v-text-field clearable v-model="companyBranch" label="Branche"></v-text-field>
          <v-text-field clearable v-model="companyContact" label="Ansprechpartner/in *" :rules="noEmptyRule" required></v-text-field>
          <v-text-field clearable v-model="companyPhone" label="Telefon *" type="tel" required></v-text-field>
          <v-text-field clearable v-model="companyEmail" label="E-Mail *" type="email" :rules="emailRules" required></v-text-field>
          <v-text-field clearable v-model="companyAmount" label="Gesuchte Mitarbeiter/innen"></v-text-field>
          <!-- <v-text-field clearable v-model="companyWorkers" label="Anzahl der gesuchten Arbeitskräfte *" required></v-text-field> -->
          <v-text-field clearable v-model="companyPlaces" label="Für den Standort / die Standorte in *" required></v-text-field>
          <v-textarea :auto-grow="true" v-model="message" label="Möchten Sie uns noch etwas mitteilen?"></v-textarea>
          <br/>
          <v-text>Ich nehme zur Kenntnis: *</v-text>
          <v-checkbox v-model="checkbox1" :rules="checkbox1Rule" label="Durch das Absenden dieser Nachricht kommt noch kein verbindlicher Vermittlungsvertrag zustande. Nach Absendung dieses Formulars werde ich zeitnah durch die AP Management LORENZ®, i.d.R. telefonisch kontaktiert. Hierbei werden die Rahmenbedingungen und weiteres besprochen. Nach Einigung wird ein entsprechender Vermittlungsvertrag geschlossen." required></v-checkbox>
          <v-text>Ich nehme zur Kenntnis: *</v-text>
          <v-checkbox v-model="checkbox2" :rules="checkbox2Rule" label="Hiermit erkläre ich mein Einverständnis, dass die AP Management LORENZ® meine vorliegenden Daten für eine ordnungsgemäße Auftragsausführung speichert. Meine Daten werden nicht an Dritte weitergegeben. Zudem erkläre ich mich einverstanden, von der AP Management LORENZ® telefonisch kontaktiert zu werden um die Auftragsvergabe abzuschließen. Diese Einverständniserklärung kann ich jederzeit widerrufen und es besteht kein Rechtsanspruch auf Nutzung meiner Daten durch die AP Management LORENZ®" required></v-checkbox>
          <v-btn :loading="loading" type="submit" color="error" @click="clearForm()">Senden</v-btn>
        </v-form>
      </v-container>
    </v-container>
    <MainFooter class="ma-0 pa-0"></MainFooter>
  </v-container>
  </template>
  
  <script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";
  import axios from "axios";

  export default {
      name: 'MainIndex',
  
      components: {
        Navigation,
        indexCarousel,
        MainFooter
      },

      
      data() {
        return {
          loading: false,
          companyNameError: false,
          companyName: '',
          companyPlace: '',
          companyPostal: '',
          companyBranch: '',
          companyCity: '',
          companyContact: '',
          companyPhone: '',
          companyEmail: '',
          companyAmount: '',
          companyPlaces: '',
          message: '',
          checkbox1: false,
          checkbox2: false,
          noEmptyRule: [
            v => !!v || 'Dieses Feld darf nicht leer sein.',
          ],
          noEmptyNumbRule: [
            v => !!v || 'Dieses Feld darf nicht leer sein.',
            v => v.length <=5 || 'Eine Postleitzahl ist nicht größer als 5 Zeichen'
          ],
          emailRules: [
            v => !!v || 'Es wird eine gültige E-Mail Adresse benötigt',
            v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Bitte geben Sie eine gültige E-Mail Adresse ein',
          ],
          checkbox1Rule: [
            v =>!!v || 'Diese Box muss bestätigt werden.',
          ],
          checkbox2Rule: [
            v =>!!v || 'Diese Box muss bestätigt werden.',
          ],
        };
      },

      methods: {
        async sendPersonalContactRequest() {
          if (this.$refs.form.validate()) {
            return;
          }
          try {
            this.loading = true;
            const response = await axios.post(`http://127.0.0.1:3000/personal-contact-request`, {
              companyName: this.companyName,
              companyPlace: this.companyPlace,
              companyPostal: this.companyPostal,
              companyBranch: this.companyBranch,
              companyContact: this.companyContact,
              companyCity: this.companyCity,
              companyPhone: this.companyPhone,
              companyEmail: this.companyEmail,
              companyAmount: this.companyAmount,
              companyPlaces: this.companyPlaces,
              message: this.message,
              checkbox1: this.checkbox1,
              checkbox2: this.checkbox2
            });
            this.loading = false;
            console.log(response.data);
          } catch (error) {
            console.log(error)
          }},
          
          clearForm() {
            this.companyName = '';
            this.companyPlace = '';
            this.companyPostal = '';
            this.companyBranch = '';
            this.companyContact = '';
            this.companyPhone = '';
            this.companyEmail = '';
            this.companyAmount = '';
            this.companyPlaces = '';
            this.companyCity = '';
            this.message = '';
            this.checkbox1 = false;
            this.checkbox2 = false;
          }
        }
        };
</script>
  