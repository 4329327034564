<template>
  <v-app>
    <Navigation/>
    <v-container fluid class="ma-0 pa-0" style="background:#f0f0f0">
    <indexCarousel></indexCarousel>    
    <v-container  fluid style="max-width: 1750px; background:#f0f0f0">
      <v-row>
        <v-col cols="12" md="6">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/happy_man.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                <v-text class="align-center text-center" style="">
                  Unser Fokus liegt bei der nachhaltigen Arbeitsvermittlung und Personalbeschaffung, bundesweit und für alle Arbeitsbereiche. <br/><br/>
                  AP Management LORENZ® verfügt über umfangreiches Know-how rund um die Suche und Vermittlung neuer Mitarbeiter an die passenden Arbeitgeber. <br/><br/>
                  Wir unterstützen Sie bei der Einstellung und entlasten Personalabteilungen bei der aufwendigen Suche nach qualifiziertem Personal. <br/><br/>
                  Unserer Maßstab ist es, Bewerber und Arbeitgeber so zusammenzubringen, dass die Qualifikationen der Bewerber zum konkreten Anforderungsprofil des Unternehmens passen. <br/><br/>
                  Durch unseren umfangreichen Bewerberpool können wir auf Ihre vakanten Stellen schnell sowie zielgerichtet reagieren und Ihnen in der Regel ein passendes Bewerberprofil anbieten.
                </v-text>
              </v-overlay>
              <div style="position: absolute;  bottom: 0px; right: 5px;">
                <v-text class="white--text mb-3">
                  <a style="color: white" href="https://www.freepik.com/free-photo/happy-young-man-pointing-his-fingers-against-white-background_3894257.htm#from_view=detail_alsolike">Image by freepik</a>
                </v-text>
              </div>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/woman_1.png" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                  <v-text class="align-center text-center" style="">
                    Für Bewerber sind unsere Leistungen stets kostenlos.
                    Bei der Vermittlung von Arbeitskräften vereinbaren wir mit dem Arbeitgeber eine marktübliche Vermittlungsprovision.
                    Wir informieren Sie gern!
                  </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/ag_pic1.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                <v-text class="align-center text-center" style="">
                  <v-btn :width="1425" :height="525" text @click="visitWebsite(firstItem.path)">
                    <router-link :to="firstItem.path" style="color: white; text-decoration: none;"> 
                    <h1>
                      {{ firstItem.title }}
                    </h1>
                  </router-link>
                  </v-btn>
                </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/ag_pic2.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                <v-text class="align-center text-center" style="">
                  <v-btn :width="1425" :height="525" text @click="visitWebsite(secondItem.path)">
                    <router-link :to="secondItem.path" style="color: white; text-decoration: none;"> 
                    <h1>
                      {{ secondItem.title }}
                    </h1>
                  </router-link>
                  </v-btn>
                </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/ag_pic3.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                <v-text class="align-center text-center" style="">
                  <v-btn :width="1425" :height="525" text @click="visitWebsite(thirdItem.path)">
                    <router-link :to="thirdItem.path" style="color: white; text-decoration: none;"> 
                    <h1>
                      {{ thirdItem.title }}
                    </h1>
                  </router-link>
                  </v-btn>
                </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/job_1.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                  <v-text class="align-center text-center" style="">
                    <v-btn :width="1425" :height="525" text @click="visitWebsite(fourthItem.path)">
                    <router-link :to="fourthItem.path" style="color: white; text-decoration: none;"> 
                    <h1>
                      {{ fourthItem.title }}
                    </h1>
                  </router-link>
                  </v-btn>
                  </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :width="1425" :height="525" elevation="11" class="ma-3 text-center mx-auto">
            <v-img src="../assets/job_2.jpg" :height="525" :width="1425">
              <v-overlay absolute color="#000000" opacity="0.45">
                <v-text class="align-center text-center">
                  <v-btn :width="1425" :height="525" text @click="visitWebsite(fifthItem.path)">
                    <router-link :to="fifthItem.path" style="color: white; text-decoration: none;"> 
                    <h1>
                      {{ fifthItem.title }}
                    </h1>
                  </router-link>
                  </v-btn>
                  </v-text>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <MainFooter class="ma-0 pa-0"></MainFooter>
  </v-container>
  </v-app>
</template>

<script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";

  export default {
    name: 'HomeView',

    data() {
            return {
              firstItem: { title: "Für Arbeitgeber", path: "/arbeitgeber", },
              secondItem: { title: "Für Bewerber", path: "/bewerber", },
              thirdItem: { title: "Kontakt zu uns!", path: "/kontakt", },
              fourthItem: { title: "Zum Stellenportal", path: "/stellenangebote", },
              fifthItem: { title: "Initiativbewerbung", path: "/initiativbewerbung", },
              impressBtn: { title: "Initiativbewerbung", path: "/initiativbewerbung", },
              DataBtn: { title: "Initiativbewerbung", path: "/initiativbewerbung", },
            };},

    components: {
      Navigation,
      indexCarousel,
      MainFooter
    },

    methods: {
              selectItem(item) {
                this.selectedItem = item;
              },
              selectSecondItem(item) {
                this.selectedItem = {};
                Object.assign(this.selectedItem, { title: this.secondSelectedItem.title }, item);
              },
              visitWebsite(path) {
                this.$router.push(path).catch(()=>{});
              },
            },
  }
</script>
