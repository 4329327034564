<template>
  <v-container fluid class="ma-0 pa-0">
    <Navigation></Navigation>
    <indexCarousel></indexCarousel>    
    <v-container class="pa-12" fluid style="max-width: 960px;">
      <v-row style="font-weight: 500;">
          <v-col cols="12" data-mve-font-change="0" class="styles_contentContainer__lrPIa textnormal styles_text__3jGMu"><p style="font-size: 36px;" class="mobile-oversized"><span style="font-size: 36px; color: #3f3f3f; " class="mobile-oversized"><span style="font-weight: bold;">Datenschutzerklärung </span> </span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper">
              <span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">
                Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
              </span>
            </p>

            <p style="font-size: 14px;" class="mobile-undersized-upper">
              <span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">
                Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
              </span>
            </p>

            
            <p style="font-size: 14px;" class="mobile-undersized-upper">
              <span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">
                Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.
              </span>
            </p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper">
              <span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">
                Unsere Datenschutzerklärung ist wie folgt gegliedert:
              </span>
            </p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">I. Informationen über uns als Verantwortliche<br>II. Rechte der Nutzer und Betroffenen<br>III. Informationen zur Datenverarbeitung</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">I. Informationen über uns als Verantwortliche</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><span style="font-weight: bold;">I. Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</span><br></span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">AP Management LORENZ UG (haftungsbeschränkt)<br>Hornstraße 15<br>09599 Freiberg<br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Mail: kontakt@ap-management.info</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"> <br><span style="font-weight: bold;">II. Rechte der Nutzer und Betroffenen</span></span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</span></p>
            
            <ul>
            <li style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</span></li>
            </ul>
            <p class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><br></span></p>
            <ul>
            <li style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</span></li>
            </ul>
            <p class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><br></span></p>
            <ul>
            <li class="mobile-undersized-upper">
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</span></p>
            </li>
            </ul>
            <p class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><br></span></p>
            <ul>
            <li class="mobile-undersized-upper">
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</span></p>
            </li>
            </ul>
            <p class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><br></span></p>
            <ul>
            <li class="mobile-undersized-upper">
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</span></p>
            </li>
            </ul>
            <p class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">III. Informationen zur Datenverarbeitung</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">a) Serverdaten</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Cookies a) Sitzungs-Cookies/Session-Cookies</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">b) Drittanbieter-Cookies</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">c) Beseitigungsmöglichkeit</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">Vertragsabwicklung</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Die von Ihnen zur Inanspruchnahme unseres Dienstleistungsangebots übermittelten Daten werden von uns zum Zwecke der Vertragsabwicklung verarbeitet und sind insoweit erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne Bereitstellung Ihrer Daten nicht möglich.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen Aufbewahrungsfristen beachten.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">Kontaktanfragen / Kontaktmöglichkeit</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.<br><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">WhatsApp</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Darüber hinaus können Sie uns Ihre Bewerbungsunterlagen oder-anfragen auch per Handy (oder PC) über den Anbieter WhatsApp übermitteln.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Hinweise zum Datenschutz bei WhatsApp finden Sie hier: https://www.whatsapp.com/legal/</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">Google-Maps</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht ausschließen, dass Google Server in den USA einsetzt.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalität unseres Internetauftritts.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><br></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln ist.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen nach den <a class="link1" style="color: #3f3f3f;" href="http://www.google.de/accounts/TOS" target="_blank" rel="noopener">Googlenutzungsbedingungen</a>&nbsp;</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://policies.google.com/terms?gl=DE&amp;hl=de" target="_blank" rel="noopener">https://policies.google.com/terms?gl=DE&amp;hl=de</a> und den <a class="link1" style="color: #3f3f3f;" href="http://www.google.com/intl/de_de/help/terms_maps.html" target="_blank" rel="noopener">Geschäftsbedingungen für Google Maps</a> </span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">https://www.google.com/intl/de_de/help/terms_maps.html.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Überdies bietet Google unter</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener">https://adssettings.google.com/authenticated</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://policies.google.com/privacy" target="_blank" rel="noopener">https://policies.google.com/privacy</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">weitergehende Informationen an.<br></span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">„Facebook“-Social-Plug-in</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Facebook ein. Bei Facebook handelt es sich um einen Internetservice der facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, betrieben, nachfolgend beide nur „Facebook“ genannt.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active" target="_blank" rel="noopener">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Facebook unter</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="noopener">https://developers.facebook.com/docs/plugins/</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">für Sie bereit.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den Servern von Facebook in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Facebook Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer Internetseiten erfasst.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sollten Sie bei Facebook eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten Informationen Ihres konkreten Besuchs von Facebook erkannt. Die so gesammelten Informationen weist Facebook womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog. „Gefällt mir“-Button von Facebook benutzen, werden diese Informationen in Ihrem Facebook-Nutzerkonto gespeichert und ggf. über die Plattform von Facebook veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Facebook ausloggen oder durch den Einsatz eines Add-ons für Ihren Internetbrowser verhindern, dass das Laden des Facebook-Plug-in blockiert wird.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Facebook in den unter</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><a class="link1" style="color: #3f3f3f;" href="https://www.facebook.com/policy.php" target="_blank" rel="noopener">https://www.facebook.com/policy.php</a></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; " class="mobile-undersized-upper"><br></span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">abrufbaren Datenschutzhinweisen bereit.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f;  font-weight: bold;" class="mobile-undersized-upper">Online-Stellenbewerbungen / Veröffentlichung von Stellenanzeigen</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren Internetauftritt bewerben zu können. Bei diesen digitalen Bewerbungen werden Ihre Bewerber- und Bewerbungsdaten von uns zur Abwicklung des Bewerbungsverfahrens elektronisch erhoben und verarbeitet.</span></p>
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir Ihre bei der Bewerbung übermittelten Daten in Ihrer Personalakte zum Zwecke des üblichen Organisations- und Verwaltungsprozesses – dies natürlich unter Beachtung der weitergehenden rechtlichen Verpflichtungen.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage für diese Verarbeitung ist ebenfalls § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Bei der Zurückweisung einer Bewerbung löschen wir die uns übermittelten Daten automatisch sechs Monate nach der Bekanntgabe der Zurückweisung. Die Löschung erfolgt jedoch nicht, wenn die Daten aufgrund gesetzlicher Bestimmungen, bspw. wegen der Beweispflichten nach dem AGG, eine längere Speicherung von bis zu vier Monaten oder bis zum Abschluss eines gerichtlichen Verfahrens erfordern.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt in der Rechtsverteidigung bzw. -durchsetzung.</span></p>
            
            <p style="font-size: 14px;" class="mobile-undersized-upper"><span style="font-size: 14px; color: #3f3f3f; " class="mobile-undersized-upper">Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten einwilligen, bspw. für Ihre Aufnahme in eine Bewerber- oder Interessentendatenbank, werden die Daten aufgrund Ihrer Einwilligung weiterverarbeitet. Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie aber natürlich jederzeit nach Art. 7 Abs. 3 DSGVO durch Erklärung uns gegenüber mit Wirkung für die Zukunft widerrufen.</span></p>
            
            
            <p class="mobile-undersized-upper"><a class="link1" href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank" rel="noopener">Muster-Datenschutzerklärung</a> der <a class="link1" href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html" target="_blank" rel="noopener">Anwaltskanzlei Weiß &amp; Partner</a></p></v-col>
        </v-row>
      </v-container>
    <MainFooter class="ma-0 pa-0"></MainFooter>
  </v-container>
</template>
  
  <script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";
  
  export default {
      name: 'MainIndex',
  
      components: {
        Navigation,
        indexCarousel,
        MainFooter
      },
  
      data: () => ({}),
    }
  </script>
  