<template>
    <v-container fluid class="ma-0 pa-0">
      <v-carousel cycle hide-delimiters :show-arrows="false">
        <v-carousel-item src="../assets/vecstock.jpg">
          <div style="position: absolute;  bottom: 0px; right: 5px;">
              <a class="white--text mb-3 text-shadow" href="https://www.freepik.com/free-ai-image/futuristic-office-design-with-modern-technology-equipment-generated-by-ai_41451963.htm#fromView=search&page=1&position=3&uuid=46300b9e-2df5-4295-a6e2-bf904a6a4478">Image by vecstock on Freepik</a>
          </div>
        </v-carousel-item>
  
        <v-carousel-item src="../assets/carousel_1.jpg">
          <div style="position: absolute;  bottom: 0px; right: 5px;">
            <a class="white--text mb-3 text-shadow" href="https://www.freepik.com/free-photo/full-shot-students-studying-indoors_31194721.htm#fromView=search&page=1&position=5&uuid=adfb3f5f-2aad-4084-af4d-e80167f62c6e">Image by freepik</a>
          </div>
        </v-carousel-item>
  
        <v-carousel-item src="../assets/vecstock_2.jpg">
          <div style="position: absolute;  bottom: 0px; right: 5px;">
            <a class="white--text mb-3 text-shadow" href="https://www.freepik.com/free-photo/people-modern-office-working-with-gadgets_1291648.htm#fromView=search&page=2&position=3&uuid=4ea3678c-a782-452b-a373-e1bb6755ea27">Image by freepik</a>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-container>
</template>

<script>
  
export default {
    name: 'indexCarousel',

    components: {
    },

    data: () => ({}),
  }
</script>