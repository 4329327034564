<template>
  <div style="height: 0; width: 0;">
    <v-app-bar app color="white" dark>
      <v-btn class="d-flex align-center" @click="visitWebsite(firstItem.path)" text>
        <v-img alt="Logo" class="mr-2" contain src="../assets/logo.png" transition="scale-transition" max-width="195" max-height="195" />
      </v-btn>
      <v-spacer></v-spacer>
      <v-icon class="pa-4" v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer" style="color:#000000;">mdi-menu</v-icon>
      
      <v-btn @click="visitWebsite(firstItem.path)" text v-if="$vuetify.breakpoint.mdAndUp">
        <router-link :to="firstItem.path" style="color: #000000; text-decoration: none;"> {{ firstItem.title }}</router-link>
      </v-btn>
      
      <v-menu offset-y v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text>
            <span style="color:#000000;"> {{ selectedItem.title }} </span>
            <v-icon style="color:#000000;">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in middleItems" :key="index" @click="visitWebsite(item.path)">
            <router-link :to="item.path" style="color: #000000; text-decoration: none;">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <v-menu offset-y v-if="secondSelectedItem && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text style="color:#000000;"> {{ secondSelectedItem.title }}
            <v-icon style="color:#000000;">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in secondMenuItems" :key="index">
            <router-link :to="item.path" style="color: black; text-decoration: none;">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="visitWebsite(lastItem.path)" text v-if="$vuetify.breakpoint.mdAndUp" style="color:#000000;">
        {{ lastItem.title }}
      </v-btn>
    </v-app-bar>

      <v-navigation-drawer v-model="drawer" temporary app color="#fafafa" right>
        <v-list >
          <v-list-item class="pa-0 ma-0" v-for="(item, index) in navBarItems" :key="index" >
                <router-link  :to="item.path" style="color: black; text-decoration: none; font-weight: 500;">
                  <v-btn block plain>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-btn>
                </router-link>
              </v-list-item>
              <v-btn block plain @click="drawer = false">
                Schließen
              </v-btn>
        </v-list>
      </v-navigation-drawer>
  </div>
  </template>
        
        
        <script>
        export default {
          name: "AppBar",
          
          data() {
            return {
            drawer: false,
            group: null,
              firstItem: { title: "Startseite", path: "/startseite", },
              selectedItem: { title: "Für Arbeitgeber"},
              middleItems: [
              { title: "Unser Angebot für Arbeitgeber", path: "/arbeitgeber" },
              { title: "Personalbedarf melden", path: "/personalbedarf-melden", },
              ],
              
              secondSelectedItem: { title: "Für Bewerber" },
              secondMenuItems: [
              { title: "Unser Angebot für Bewerber", path: "/bewerber", },
              // { title: "Stellenangebote", path: "/stellenangebote", },
              // { title: "Initiativbewerbung", path: "/initiativbewerbung", },
            ],
              lastItem: { title: "Kontakt", path: "/kontakt", },
              navBarItems: [
              { title: "Startseite", path: "/startseite", },
              { title: "Für Arbeitgeber", path: "/arbeitgeber" },
              { title: "Personalbedarf", path: "/personalbedarf-melden", },
              { title: "Für Bewerber", path: "/bewerber", },
              // { title: "Stellenangebote", path: "/stellenangebote", },
              // { title: "Initiativbewerbung", path: "/initiativbewerbung", },
              { title: "Kontakt", path: "/kontakt", },
              ]
            };
          },

            watch: {
              group() {
                this.drawer = false
              },
            },
            
            methods: {
              selectItem(item) {
                this.selectedItem = item;
              },
              selectSecondItem(item) {
                this.selectedItem = {};
                Object.assign(this.selectedItem, { title: this.secondSelectedItem.title }, item);
              },
              visitWebsite(path) {
                this.$router.push(path).catch(()=>{});
                this.scrollToTop();
              },
              scrollToTop() {
                window.scrollTo(0, 0);
              }
            },
          };
        </script>