<template>
  <v-container fluid class="ma-0 pa-0" style="background:#f0f0f0">
    <Navigation></Navigation>
    <indexCarousel></indexCarousel>    
    <v-container fluid style="max-width: 1750px; background:#f0f0f0">
		<v-row>
			<v-col cols="12" md="12" class="text-center ma-4">
				<h1 style="font-size: 32px; color: #5b5b5b; text-shadow: #d4d4d4 3px 3px 5px; font-family: Arial Black, sans-serif; font-style: italic;">Sie suchen eine passende Arbeit ?</h1>
			</v-col>
			
			<v-col cols="12" md="7" class="text-center" style="padding: 0;">
				<h1 style="font-size: 32px; color: #5b5b5b; text-shadow: #d4d4d4 3px 3px 5px; font-family: Arial Black, sans-serif; font-style: italic;">Wozu benötigen Sie eine Arbeitsvermittlung ?</h1>
				<v-text style="font-weight: 600; font-size: larger; width: 30%; position: relative;">
					Qualifikationen allein reichen heute oft nicht mehr aus, denn der<br/>
					Arbeitsmarkt ist groß und unübersichtlich.<br/><br/>
					Die Stellenvermittlung erfolgt immer häufiger über direkte Kontakte.<br/><br/>
            </v-text>

				<v-text>
					<h2 class="ma-2" style="text-shadow: #d4d4d4 3px 3px 5px;">Wir bieten Ihnen</h2>
					<div data-mve-font-change="-5" class="styles_contentContainer__lrPIa textnormal styles_text__3jGMu"><p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Direkten Zugang zu Unternehmen, die Mitarbeiter suchen</span><br></p>
					<p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Kontakt zu den richtigen Ansprechpartnern</span><br></p>
					<p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Aktuelle Informationen zum Stand Ihrer Bewerbung</span><br></p>
					<p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Unterstützung zum Vorstellungsgespräch</span><br></p>
					<p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Fokus auf Ihre persönlichen Voraussetzungen und Wünsche</span><br></p>
					<p style="font-size: 18px; line-height: 1.8;"><span style="font-size: 18px; color: #3f3f3f; font-family: Georgia, serif; font-weight: normal; text-shadow: #d4d4d4 3px 3px 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ✔&nbsp;&nbsp; Persönliche Betreuung</span></p></div>
				</v-text>
			</v-col>
			<v-col cols="12" md="5">
			<v-img class="mr-8" src="../assets/job_1.jpg" :height="525" :width="1425"></v-img>
			</v-col>
    </v-row>
    </v-container>
	
	<v-container fluid class="pa-0" style="background:#f0f0f0" v-if="$vuetify.breakpoint.mdAndUp">
		<v-img src="../assets/bg_black.jpg" :max-height="450">
			<v-row>
				<v-col cols="12" md="4">
					<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
						<v-img src="../assets/job_1.jpg" :height="425" :width="425">
							<v-overlay absolute color="#000000" opacity="0.45">
								<v-text class="align-center text-center" style="">
									<v-btn :width="1425" :height="525" text  @click="visitWebsite(firstItem.path)">
										<h1>
											Stellenportal
										</h1>
									</v-btn>
								</v-text>
							</v-overlay>
						</v-img>
					</v-card>
				</v-col>

				<v-col cols="12" md="4">
					<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
						<v-img src="../assets/job_2.jpg" :height="425" :width="425">
							<v-overlay absolute color="#000000" opacity="0.45">
								<v-text class="align-center text-center" style="">
									<v-btn :width="1425" :height="525" text  @click="visitWebsite(secondItem.path)">
										<h1>
											Initiativbewerbung
										</h1>
									</v-btn>
								</v-text>
							</v-overlay>
						</v-img>
					</v-card>
				</v-col>

				<v-col cols="12" md="4">
					<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
						<v-img src="../assets/ag_pic3.jpg" :height="425" :width="425">
							<v-overlay absolute color="#000000" opacity="0.45">
								<v-text class="align-center text-center" style="">
									<v-btn :width="1425" :height="525" text  @click="visitWebsite(thirdItem.path)">
										<h1>
											Kontakt zu uns!
										</h1>
									</v-btn>
								</v-text>
							</v-overlay>
						</v-img>
					</v-card>
				</v-col>
			</v-row>
		</v-img>

		<v-col cols="12">
		<v-container fluid class="text-center">
			<v-divider></v-divider>
			<h1 style="text-shadow: #d4d4d4 3px 3px 5px;">Welche Kosten entstehen bei erfolgreicher Arbeitsvermittlung ?</h1>
			<h2 style="font-weight: 500;text-shadow: #d4d4d4 3px 3px 5px;">Für Bewerber sind unsere Leistungen stets kostenlos !</h2>
			<v-divider></v-divider>
		</v-container>
	</v-col>
	</v-container>


	<v-container fluid class="pa-0" style="background:#f0f0f0" v-if="$vuetify.breakpoint.smAndDown">
		<v-img src="../assets/bg_black.jpg" :min-height="515">
			<v-col cols="12">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/job_1.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(firstItem.path)">
								<h1>
									Stellenportal
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
			<v-col cols="12">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/job_2.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(secondItem.path)">
								<h1>
									Initiativbewerbung
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
			
			<v-col cols="12">
			<v-card :width="425" :height="425" elevation="11" class="ma-3 text-center mx-auto">
				<v-img src="../assets/ag_pic3.jpg" :height="425" :width="425">
					<v-overlay absolute color="#000000" opacity="0.45">
						<v-text class="align-center text-center" style="">
							<v-btn :width="1425" :height="525" text  @click="visitWebsite(thirdItem.path)">
								<h1>
									Kontakt zu uns!
								</h1>
							</v-btn>
						</v-text>
					</v-overlay>
				</v-img>
			</v-card>
			</v-col>
		</v-img>

		<v-col cols="12">
		<v-container fluid class="text-center">
			<v-divider></v-divider>
			<h1 style="text-shadow: #d4d4d4 3px 3px 5px;">Welche Kosten entstehen bei erfolgreicher Arbeitsvermittlung ?</h1>
			<h2 style="font-weight: 500;text-shadow: #d4d4d4 3px 3px 5px;">Für Bewerber sind unsere Leistungen stets kostenlos !</h2>
			<v-divider></v-divider>
		</v-container>
	</v-col>
	</v-container>
	<MainFooter class="ma-0 pa-0"></MainFooter>
	</v-container>
</template>
  
  <script>
  import Navigation from '../components/Navigation.vue'
  import indexCarousel from "../components/indexCarousel";
  import MainFooter from "../components/MainFooter";
  
  export default {
      name: 'MainIndex',

	data() {
		return {
			firstItem: { title: "Für Arbeitgeber", path: "/stellenangebote", },
			secondItem: { title: "Für Bewerber", path: "/initiativbewerbung", },
			thirdItem: { title: "Kontakt zu uns!", path: "/kontakt", }};
		},
	
	methods: {
		visitWebsite(path) {
			this.$router.push(path).catch(()=>{});
			this.scrollToTop();
		},
		
		scrollToTop() {
			window.scrollTo(0, 0);
		}
	},
	
	created() {
		this.scrollToTop();
	},

	components: {
        Navigation,
        indexCarousel,
        MainFooter
      },
}
  </script>
  